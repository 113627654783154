import React from 'react';
import texture from 'assets/images/texture/texture-2.png';
import IntergrationImage from "assets/images/lastbit-22.jpg";
import { motion } from "framer-motion";
import PlayButton from 'components/playButton';

const LastUpdate: React.FC = () => {
    const motionSetting = (delay: number) => {
        return {
            initial: {
                opacity: 0,
                y: -30,
            },
            whileInView: {
                opacity: 1,
                x: 0,
                y: 0,
            },
            viewport: { once: true },
            transition: {
                ease: [0.6, 1, 0.5, 1],
                opacity: { duration: 1.5, delay: delay },
                duration: 1,
                delay: delay,
            },
        };
    };
    return (
        <section id='last-update'>
            <div
                className='relative flex flex-col items-center min-h-screen h-full snap-start bg-no-repeat bg-cover pt-20'
                style={{backgroundImage: `url(${texture})`}}
            >
                <div className="flex flex-col justify-center items-center max-w-6xl px-4 mx-auto w-full">
                    <div className="flex w-full flex-col flex-nowrap items-center gap-4">
                        <motion.div {...motionSetting(0)}>
                            <h4
                                className="text-wrap break-words text-center text-2xl font-black capitalize md:text-start md:text-[32px] lg:text-[32px] xl:text-5xl"
                                style={{ letterSpacing: "0.01em", lineHeight: "140%" }}
                            >
                                <span className='text-brand-2'>Survivor’s</span> Update
                            </h4>
                        
                        </motion.div>
                        <motion.div {...motionSetting(0.3)}  className="flex flex-col items-center">
                            <p
                                className="text-center text-sm leading-relaxed md:text-base xl:text-lg"
                                style={{ lineHeight: "160%", letterSpacing: "0.02em" }}
                            >
                                Explore the latest features in LastBit. Strengthen your defenses, battle new zombie types with unique abilities, and adapt to an evolving resource economy. Every decision counts as you fight to survive 100 days and claim the ultimate prize: 1 Bitcoin.
                            </p>
                        </motion.div>
                        <motion.div {...motionSetting(0.6)} className="flex flex-col items-center mt-4">
                            <PlayButton />
                        </motion.div>
                    </div>
                    <div className='mt-12'>
                        <motion.div {...motionSetting(1)} className="flex justify-center max-w-72 w-full overflow-hidden xl:max-h-none">
                            <img
                                className="h-auto w-full max-w-md object-contain border-brand-2 border-2"
                                src={IntergrationImage}
                                alt="lastbit-cover"
                            />
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LastUpdate;