import React from 'react';
import bgImage from 'assets/images/lastbit-2.png';
import PlayButton from 'components/playButton';
import texture from 'assets/images/texture/texture-3.png';
import ScrollButton from 'components/scrollButton';

const Hero: React.FC = () => {
    return (
        <section id="hero">
            <div
                className='relative flex flex-col items-center min-h-screen h-full snap-start bg-no-repeat bg-cover pb-20'
                style={{backgroundImage: `url(${texture})`}}
            >
                <div className="mx-auto max-w-6xl px-4">
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-24 gap-10 items-center">
                        <div className="col-span-1">
                            <div className="px-4 items-center lg:items-start w-full flex flex-col">
                                <h1 className='font-bold lg:font-black text-3xl lg:text-4xl !leading-snug mb-2 text-center lg:text-left'>
                                    <span className='text-brand-2'>LASTBIT: </span>Welcome to the Ultimate Survival Challenge 
                                </h1>
                                <h4 className='mb-6 lg:text-lg text-center lg:text-left'>
                                    LastBit delivers an unparalleled survival strategy game experience, 
                                    combining intense zombie battles, resource management, and tactical 
                                    decision-making in a post-apocalyptic world. Fight to survive, 
                                    build your legacy, and claim the ultimate reward of 1 Bitcoin.
                                </h4>
                                <div className="flex flex-col items-center">
                                    <PlayButton />
                                    <p className='font-red-hat text-gray_brand-1 text-sm mt-4'>Install Game In Your Browser</p>
                                </div>
                            </div>
                        </div>
                        <div className="relative lg:items-start col-span-1 flex justify-center">
                            <img src={bgImage} className='rounded-full md:size-[450px] size-72 object-cover' alt='' />
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <ScrollButton section='feature' />
                </div>
            </div>
        </section>
    );
};

export default Hero;