import React from 'react';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import Image1 from "assets/images/lastbit-212.jpg";
import Image2 from "assets/images/lastbit-2142.jpg";
import Image3 from "assets/images/lastbit-213.jpg";
import texture from 'assets/images/texture/texture-2.png';
import PlayButton from 'components/playButton';

interface CardPropsType {
    id: number;
    title: string;
    image: string;
    description: string;
  }

const Card = ({ id, title, image, description }: CardPropsType) => {
    return (
        <motion.div
            initial={{
                opacity: 0,
                y: -30,
            }}
            whileInView={{
                opacity: 1,
                y: 0,
            }}
            viewport={{ once: true }}
            transition={{
                ease: [0.6, 1, 0.5, 1],
                opacity: { duration: 1, delay: 0.3 * id },
                duration: 1,
                delay: 0.2 * id,
            }}
            className="relative z-10 flex w-full flex-col flex-nowrap items-center justify-start gap-6 rounded-3xl bg-border-gray_brand border border-gray_brand-2/10 backdrop-blur-sm p-6 md:gap-12"
        >
            <div className="rounded-3xl">
                <img
                    src={image}
                    alt="background"
                    className="h-full w-full object-fill object-center max-w-72"
                    style={{ borderRadius: "inherit" }}
                />
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
                <h4 className="text-sm font-bold md:text-xl">{title}</h4>
                <p className="text-center text-xs text-gray_brand-1 md:text-base">
                    {description}
                </p>
            </div>
        </motion.div>
    );
};
  
const CardSwiper = ({ cards }: { cards: CardPropsType[] }) => {
    return (
        <Swiper
            modules={[Autoplay, Pagination]}
            slidesPerView={2.4}
            centeredSlides={false}
            spaceBetween={16}
            pagination={{ clickable: true }}
            loop={true}
            speed={1000}
            autoplay={{
                delay: 1600,
                disableOnInteraction: false,
            }}
            effect="fade"
            fadeEffect={{ crossFade: true }}
            className="w-full"
            breakpoints={{
                640: {
                    slidesPerView: 2.2,
                },
                768: {
                    slidesPerView: 1.8,
                },
                1024: {
                    slidesPerView: 2.4,
                },
            }}
        >
            {[...cards, ...cards].map(({ id, title, image, description }, index) => (
                <SwiperSlide key={id + index} className="h-auto w-fit">
                    <div className="relative flex w-full flex-col flex-nowrap items-center justify-start gap-8 rounded-3xl border border-gray_brand-2/10 p-4">
                        <div className="rounded-3xl">
                            <img
                                src={image}
                                alt="background"
                                className="h-full w-full object-fill object-center"
                                style={{ borderRadius: "inherit" }}
                            />
                        </div>
                        <div className="flex flex-col items-center justify-center gap-3">
                            <h4 className="text-xl font-bold">{title}</h4>
                            <p className="text-center text-sm text-gray_brand-1">
                                {description}
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

const Feature: React.FC = () => {
    const data = [
        {
          id: 1,
          image: Image3,
          title: 'Build, Gather, and Survive',
          description: 'Take on the challenge of managing resources, building defenses, and surviving 100 in-game days against relentless waves of zombies.',
        },
        {
          id: 2,
          image: Image2,
          title: 'Face the Undead Horde',
          description: 'Plan your defenses, deploy soldiers, and use automated systems to combat increasingly difficult zombie waves as the days progress.',
        },
        {
          id: 3,
          image: Image1,
          title: 'Strategic Gameplay',
          description: 'Every decision matters—repair, fight, and build to secure your survival and claim the ultimate reward of 1 Bitcoin.',
        },
    ];
    return (
        <section id='feature'>
            <div
                className='relative flex flex-col items-center min-h-screen h-full snap-start bg-no-repeat bg-cover py-20'
                style={{backgroundImage: `url(${texture})`}}
            >
                <motion.div
                    initial={{
                        opacity: 0,
                        y: -30,
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        x: 0,
                    }}
                    viewport={{ once: true }}
                    transition={{
                        ease: [0.6, 1, 0.5, 1],
                        opacity: { duration: 1.5, delay: 0.3 },
                        duration: 1,
                        delay: 0.2,
                    }}
                    className="flex shrink-0 flex-col justify-start"
                >
                    <p
                        className="text-wrap break-words text-center text-2xl font-black capitalize md:text-start md:text-[32px] lg:text-[32px] xl:text-5xl"
                        style={{ letterSpacing: "0.01em", lineHeight: "140%" }}
                    >
                    <span className='text-brand-2'>Survive.</span> Build. Conquer.
                    </p>
                </motion.div>
                <div className="mx-auto max-w-6xl px-4">
                    {/* <div className="hidden w-full md:block xl:hidden">
                        <CardSwiper cards={data} />
                    </div> */}

                    <div className="grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-3 md:gap-6 grid">
                        {data.map((card) => (
                            <Card key={card.id} {...card} />
                        ))}
                    </div>
                    <div className="flex items-center justify-center mt-6">
                        <PlayButton />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Feature;