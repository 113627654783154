import React, { useEffect, useState } from 'react';

const Typewriter = React.forwardRef(({ text, speed, initialWords }: {text: string, speed: number, initialWords: number}, ref: any) => {
    const initialText = text.slice(0, initialWords);
    const [displayedText, setDisplayedText] = useState(initialText);

    useEffect(() => {
        let index = initialWords;
        let interval: any;

        const startTyping = () => {
            interval = setInterval(() => {
                if (index < text.length - 1) {
                    setDisplayedText((prev) => prev + text[index]);
                    index++;
                } else {
                    clearInterval(interval);
                }
            }, speed);
        };

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        startTyping();
                        observer.unobserve(ref.current); // متوقف کردن نظارت بعد از شروع
                    }
                });
            },
            { threshold: 0.1 } // وقتی که 10% از عنصر قابل مشاهده است
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            clearInterval(interval);
            observer.disconnect();
        };
    }, [text, speed, ref]);

    return <p ref={ref}>{displayedText}</p>;
});

export default Typewriter;
