import React from 'react';
import Footer from './components/footer';
import Stats from './components/stats';
import LastUpdate from './components/lastUpdate';
import Feature from './components/feature';
import Hero from './components/hero';

const Home: React.FC = () => {
    return (
        <div>
            <Hero />
            <Feature />
            <Stats />
            <LastUpdate />
            <Footer />
        </div>
    );
};

export default Home;