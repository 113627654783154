import React from 'react';

// assets
import logo from 'assets/images/lastbitligi-02.png'
import line from 'assets/images/texture/new-line.png';
import Twitter from 'assets/svgs/x';
import Telegram from 'assets/svgs/telegram';
import bgTexture from 'assets/images/texture/texture-1.png';

// routes
import socials from 'constant/social';
import { Link } from 'react-router-dom';
import { ArrowDownIcon } from 'lucide-react';
import PlayButton from 'components/playButton';

const Footer: React.FC = () => {
    const scrollToSection = () => {
        const scrollSection = document.getElementById('hero');
        if (scrollSection) {
            scrollSection.scrollIntoView({ behavior: 'smooth' });
        };
    };
    return (
        <section id='footer'>
            <div
                className='flex flex-col items-center bg-contain bg-bottom border-t border-brand_secondary-1 bg-no-repeat pt-20'
                style={{backgroundImage: `url(${bgTexture})`, scrollSnapAlign: 'end'}}
            >
                <div className='flex flex-col justify-center items-center w-full mb-10'>
                    <img className='size-48' src={logo} alt='lastbit_logo' />
                    <p className='max-w-3xl mx-auto text-center mt-4 mb-8 px-4'>
                        LastBit delivers an unparalleled survival strategy game experience, combining intense zombie
                        battles, resource management, and tactical decision-making in a post-apocalyptic world. Fight
                        to survive, build your legacy, and claim the ultimate reward of 1 Bitcoin.
                    </p>
                    <PlayButton />
                    <div className="mt-8">
                        <p className='font-red-hat'>Follow us</p>
                        <div className="flex flex-nowrap items-center gap-6 mt-3">
                            <Link to={socials.twitter} target="_blank">
                                <Twitter className="cursor-pointer fill-white size-6 hover:fill-gray-400" />
                            </Link>
                            <Link to={socials.telegram} target="_blank">
                                <Telegram className="cursor-pointer fill-white size-6 hover:fill-gray-400" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col max-w-6xl justify-center items-center w-full px-6 h-[100px]">
                    <div className="flex w-full h-0.5" style={{backgroundImage: `url(${line})`}} />
                    <div className="w-full gap-3 my-4 md:my-0 md:h-full flex flex-col-reverse md:flex-row items-center justify-between">
                        <p className='font-red-hat text-gray_brand-2'>©2024 ALL RIGHTS RESERVED LASTBIT</p>
                        <button onClick={scrollToSection} className="flex gap-4 items-center transition-all cursor-pointer text-gray_brand-2 hover:text-white">
                            <p className='font-red-hat'>Back To Top</p>
                            <div className="up-down-animation-6">
                                <ArrowDownIcon className='rotate-180' />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;