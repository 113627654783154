import React from 'react';
import buttonBG from 'assets/svgs/scroll-button.svg';
import { ArrowDown } from 'lucide-react';

const ScrollButton: React.FC<{section: string}> = ({ section }) => {
    const scrollToSection = () => {
        const scrollSection = document.getElementById(section);
        if (scrollSection) {
            scrollSection.scrollIntoView({ behavior: 'smooth' });
        };
    };
    return (
        <button onClick={scrollToSection} className='cursor-pointer w-fit flex items-center flex-col gap-1 up-down-animation-6'>
            <div className="rounded-full size-14 flex items-center justify-center bg-no-repeat bg-contain relative"
                style={{backgroundImage: `url(${buttonBG})`}}
            >
                <ArrowDown className='size-5' />
            </div>
            <p className='font-red-hat text-gray_brand-1 text-sm'>Scroll Down</p>
        </button>
    );
};

export default ScrollButton;