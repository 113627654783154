import React from 'react';
import socials from 'constant/social';
import { Link } from 'react-router-dom';
import buttonBackground from 'assets/images/texture/button.png';


const PlayButton: React.FC = () => {
    return (
        <Link to={socials.game} target='_blank'>
            <div className="relative flex hover:text-brand-2 transition-all ">
                <div
                    className="bg-no-repeat bg-contain h-14 w-56 flex items-center justify-center relative"
                    style={{backgroundImage: `url(${buttonBackground})`}}
                >
                    <p className='text-lg'>PLAY NOW</p>
                </div>
            </div>
        </Link>
    );
};

export default PlayButton;