import { useMemo } from "react";
import { createTheme } from "@mui/material";

const useTheme = () => {
    const theme = useMemo(() => {
        return createTheme({
            cssVariables: true,
            palette: {
                primary: {
                    main: '#514cea',
                    dark: '#1814bc',
                    light: '#d2ceff',

                    "100": '#d2ceff',
                    "200": '#514cea',
                    "300": '#1814bc',
                    "400": '#1f1e3e',
                },
                secondary: {
                    main: '#1B1B21',
                    light: '#242333',

                    "100": "#242333",
                    "200": "#1B1B21",
                },
                grey: {
                    "100": "#c5c5c9",
                    "200": "#777777",
                },
                background: {
                    paper: '#090708',
                    default: '#090708',
                },
            },
            typography: {
                allVariants: {
                    color: 'white',
                    fontFamily: '"Mabar", sans-serif;',
                },
            },
        });
    }, []);

    return theme;
};

export default useTheme;