import React, { useRef } from 'react';
import texture from 'assets/images/texture/texture-3.png';
import { motion } from "framer-motion";
import PlayButton from 'components/playButton';
import Typewriter from 'components/typewriter';
import ScrollButton from 'components/scrollButton';

const Stats: React.FC = () => {
    const statsList = [
        {value: 60, title: 'Active Players', prefix: '+', suffix: 'player'},
        {value: 60, title: 'Active Players', prefix: '+', suffix: 'player'},
        {value: 60, title: 'Active Players', prefix: '+', suffix: 'player'},
    ];
    const typewriterRef = useRef(null);
    return (
        <div className="stats">
            <div
                className='relative flex flex-col justify-between gap-6 items-center min-h-screen h-full snap-start bg-no-repeat bg-cover py-20'
                style={{backgroundImage: `url(${texture})`}}
            >
                <div className="flex flex-col justify-center items-center max-w-6xl px-4 mx-auto w-full">
                    <motion.div {...motionSetting(0)}>
                        <h3
                            className="text-wrap break-words text-center text-2xl font-black capitalize md:text-start md:text-[32px] lg:text-[32px] xl:text-5xl"
                            style={{ letterSpacing: "0.01em", lineHeight: "140%" }}
                        >
                            Stats
                        </h3>
                    
                    </motion.div>
                    <p
                        className="text-center text-sm leading-relaxed md:text-base xl:text-lg h-16"
                        style={{ lineHeight: "160%", letterSpacing: "0.02em" }}
                    >
                        <Typewriter
                            initialWords={50}
                            speed={60}
                            ref={typewriterRef}
                            text='LastBit is a survival strategy game that challenges players to survive 100 in-game days in a post-apocalyptic world filled with relentless zombie hordes'
                        />
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full mt-12 gap-4">
                        {statsList.map((item) => (
                            <div className="col-span-1 rounded-3xl border border-gray_brand-1/30 bg-gray_brand-2/5 p-4 w-full hover:bg-brand-2 transition-all hover:scale-105">
                                <p className='font-red-hat text-lg'>{item.title}</p>
                                <p className='text-3xl md:text-5xl mt-2 flex items-end flex-nowrap w-full'>
                                    <span>{item.prefix}</span>
                                    {item.value}
                                    <span className='text-lg mb-1 mx-2 text-gray_brand-1'>{item.suffix}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col items-center uppercase gap-6 mt-10">
                        <PlayButton />
                        <p className='font-red-hat text-gray_brand-1 text-sm'>Install Game In Your Browser</p>
                    </div>
                </div>
                <div className="flex justify-center">
                    <ScrollButton section='last-update' />
                </div>
            </div>
        </div>
    );
};

const motionSetting = (delay: number) => {
    return {
        initial: {
            opacity: 0,
            y: -30,
        },
        whileInView: {
            opacity: 1,
            x: 0,
            y: 0,
        },
        viewport: { once: true },
        transition: {
            ease: [0.6, 1, 0.5, 1],
            opacity: { duration: 1.5, delay: delay },
            duration: 1,
            delay: delay,
        },
    };
};

export default Stats;