import React from 'react';

// components import
import Header from './components/Header';
import Footer from './components/Footer';

// type import
import type { LayoutPropsType } from 'interface/base';

const Layout: React.FC<LayoutPropsType> = ({children, withoutHeader, withoutFooter}) => {
    return (
        <div>
            {/* {!withoutHeader && <Header />} */}
            <main>{children}</main>
            {/* {!withoutFooter && <Footer />} */}
        </div>
    );
};

export default Layout;