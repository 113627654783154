import React from 'react';

// routes
import routes from 'constant/routes';
import Layout from 'components/layout';
import { Route, Routes } from "react-router-dom";

// pages
import Home from 'page/home';

// types
import type { RoutesPropsType } from 'interface/base';


const AppRoutes: React.FC = () => {
    return (
        <Routes>
            {route.map((item: RoutesPropsType) => {
                if (item.avalible)
                    return (
                        <Route
                            key={item.path}
                            path={item.path}
                            element={
                                <Layout withoutFooter={item?.withoutFooter} withoutHeader={item?.withoutHeader}>
                                    <item.component />
                                </Layout>
                            }
                        />
                    );
                else return null;
            })}
        </Routes>
    );
};

const route: RoutesPropsType[] = [
    {
        path: routes.base,
        avalible: true,
        component: Home,
        isDashboard: false,
    },
];

export default AppRoutes;